import React from "react";
import "../Styles/health.scss";
import { MdCall } from "react-icons/md";
const Health = (props) => {
  return (
    <>
    {props.data && props.data.map((data) => {
      return(
      <>
        <div className="health-parent parent">
        {/* <div className="img-text">
          <div className="overlay"></div>

          <div className="text-wrapper cont">
            <div className="text-wrapper-inner">
              <h2 className="t-desc ">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Enim
                deserunt et cupiditate obcaecati reprehenderit officiis laborum
                deleniti neque quaerat iusto!
              </h2>
              <a href="" className="btn">
                Read more
              </a>
            </div>
          </div>
        </div> */}

        <div className="health-cont cont">
          <div className="left bg-img-cover"></div>
          <div className="right">
            <h2>{data.Health_title}</h2>
            <p>{data.Health_desc}
            </p>
            <a href="tel:8379832555" className="icon"><MdCall />{data.number} </a>
          </div>
        </div>
      </div></>
     
    );
  })}
    </>
  );
};

export default Health;
