import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { MdCall } from "react-icons/md";
import "./popup.scss";

const Popup = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
    {props.data.map((data) =>{
      return(
        <>
        {isOpen && (
        <div className="modal-parent parent">
          <div className="pop-box container">
            <div className="close-box">
            <div className="close" onClick={closeModal}>
              <GrClose />
            </div>
            </div>
            

            <div className="contact-right-model">
              <div className="first-sec">
              <h2 className="contact-headding">{data.Health_title}</h2>
              <p className="desc-p">{data.Health_sub}
              </p>
              </div>
              <div className="left">
                <a href="tel:8379832555" className="icon"><MdCall />{data.number}</a>
              

                <a href="/Health">
                  <p className="linked">{data.Read_more_btn}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}</>
      )

    })}
      
    </>
  );
};

export default Popup;
