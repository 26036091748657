const english = [

    { 
      
      
      // header==============================
    Header_logo: "Sambhaji Patil Nilangekar",
    Header_home: "Home",
    Header_about: "About",
    Header_story: "Success Story",
    Header_marathwada: "Marathwada Coach Factory",
    Header_Jal: "Jal Saksharta Rally",
    Header_Taas: "72 Tass Annatyag Andolan ",
    Header_Latur: "Jan Jagar Samvad",
    Header_Atal: "Atal Mahaarogya Shibir",
    Header_Namo_maharojgar: "Namo Maharojgar Melava",
    Header_indraprashtha: "Indraprasth Jalbhumi Abhiyan",
    Header_akka: "Akka Foundation",
    Header_Gallery: "Gallery",
    Header_Blog: "Blogs",
    Header_contact: "Contact us",

     // footer =============================
     Footer_quick: "Quick Links",
     Footer_home: "Home",
     Footer_about: "About",
     Footer_gallery: "Gallery",
     Footer_blog: "Blog",
     Footer_contact: "Contact us",
     Footer_story: "Success Story",
     Footer_samrudha: "Samrudha Shetkari Abhiyan",
     Footer_indraprashtha: "Indraprastha Jalbhumi Abhiyan",
     Footer_harit: "Harit Latur",
     Footer_shiv: "Shivjayanti",
     Footer_anandi: "Project Anandi",
     Footer_drushti: "Drushti Abhiyan",
     Footer_Legal: "Legal",
     Footer_privacy: "Privacy policy",
     Footer_cookies: "Cookies policy",
     Footer_address: "Old Balaji Temple, Aurad(Shah), Tahsil Nilanga",

      // contact page====================================

    Contact_heading: "Join Us in Shaping Tomorrow!",
    Contact_para: "Have any concerns or queries? Fill out the form, and we'll handle your message with urgency and confidentiality!",
    Contact_first_name: "First Name",
    Contact_last_name: "Last Name",
    Contact_phone: "Phone",
    Contact_email: "Email",
    Conatct_msg: "Your message goes here...!",
    Contact_send: "Send Message",
    Contact_add: "Address",
    Contact_para_add: "Old Balaji Temple, Aurad(Shah), Tahsil Nilanga",

    // page not found========================================
    page_not_button: "Back to home page",

    // gallery page==================================

    Gallery_election: "Elections Posts",
    Gallery_marketing: "Marketing Posts",
    Gallery_calender: "Calender Posts",
    Gallery_Photo:"Photo",
    Gallery_Videos:"Videos",
    Gallery_Media:"Media",
    Gallery_Stock:"Stock",

     // home_page=========================
     Home_slider1:"The relentless pursuit of progress!",
     Home_slider2:"Latur's Own Leader, A Farmer's Friend",
     Home_slider3:"Progress-oriented visionary leadership!",
     

  
     Home_subheading:"Sambhaji Patil Nilangekar:",
     Home_subheading1:"A Commitment to Service",
     Home_subdescription:"Those who know Sambhaji Patil Nilangekar know his dedication to public service is unwavering. Inspired by the ideals of the late Atal Bihari Vajpayee, who believed politics should be a platform for national service, Sambhaji found his calling. His journey began in 2004, not under his own name on the ballot, but under his mother's, Smt. Rupatai Patil Nilangekar.  Encouraged by Sambhaji's belief in her, she entered the Lok Sabha elections, unseating a formidable six-term incumbent. This historic victory marked a turning point for the BJP in Latur and a testament to the collective effort and unwavering spirit of the community. Witnessing his mother's success and impact, Sambhaji decided to enter the political arena himself. Later that year, he contested the assembly elections and became the youngest MLA in the state. This win wasn't just a personal achievement but a recognition of the trust people placed in him. Since then, Sambhaji has tirelessly served his constituency. He approaches every challenge with a learning mindset, constantly seeking improvement.  His dedication and collaborative spirit are what truly define him.",

     Home_btn:"Read More",
     Read_less:"Read less",



    //  about page ===========================================================
    About_subheading:"Words of Gratitude and Joy from My Heart…",
    About_subdescription:"Dear Brothers and Sisters,",
    About_subdescription1:"Hello!",
    About_subdescription2:" For the past twenty years, I have dedicated myself to politics, always striving to honour the promise of public service made by our esteemed leaders: former Chief Minister Dr Shivajirao Patil Nilangekar, former MLA Late Dilipraoji Patil Nilangekar, and our former MP from Latur, Smt. Rupatai (Akka) Patil Nilangekar. Our mission has always been to ensure growth and development for every citizen of Latur district, including Nilanga. To achieve that goal, we have launched numerous projects that are ongoing throughout the district. The love and support I have received from you all have been overwhelming, helping me rise from an ordinary worker to a state cabinet minister. My contributions to the development of Maharashtra are all thanks to your blessings and encouragement. Your unwavering support has allowed me to achieve many milestones for our Latur district. We established the country's first metro coach and the fourth railway coach factory, launched a water grid project to end drought in Latur and Dharashiv districts, developed district roads, and secured approval for a Super Speciality Hospital in Latur. For the first time in our district's history, we gained power over the Zilla Parishad and Municipal Corporation. Embracing this chance for public service, every BJP worker, including myself, has worked hard to make a difference. The decisions taken by the Latur Z.P. were celebrated nationwide, setting a standard and bringing national recognition to our district. This was all made possible because of your cooperation and support.",
    About_subdescription4:"Today, our district is rapidly progressing on the path of development. We aim to maintain this pace with the cooperation of all of us. We face many challenges, including water, industry, employment, health, and youth displacement in the district. To address these, we have implemented numerous service activities such as the Indraprastha Jalbhumi Abhiyan, Water Literacy Rally, Jan Jagar Samvad, Maharojgar Melava, and Health Camps. Government officials are also making continuous efforts to approve various development works for the district. But we need your blessings to accelerate this work.  Whether it is Nilanga, Latur City, or Latur District, everyone has cared for me as their own from the beginning. So, I, Sambhaji, pledge to do everything in my power to ensure that the people of Latur never have to shed tears. You have supported me until now, and I am responsible for taking care of you. I will be with you through thick and thin. With Nilkantheshwara as my witness, I promise that I will continue to serve you as long as there is life in my body. This will guide the development of Latur in areas such as education, employment, health, and water. For this, I need your strong support and abundant blessings. We will work together, with your support, to build a prosperous and developed Latur!",
    About_subdescription5:"With heartfelt gratitude and commitment,",
    About_heading4:"Yours sincerely,",
    About_heading5:"Sambhajirao Patil Nilangekar",
    About_heading6:"Former Guardian Minister, Latur",




       // Marathwda coach factory===========================================

      Marathwada_coach_factory_heading:"Marathwada Railway Coach Factory",
      Marathwada_coach_factory_para:"The Marathwada Railway Coach Factory stands as the largest government project in the region, a testament to the tireless efforts of MLA Sambhaji Patil Nilangekar. This significant initiative, secured through persistent advocacy by Sambhaji Patil, has garnered praise from senior leaders and officials who credit him for its establishment.      Initiated by the Central Government and Ministry of Railways, this factory marks a pioneering achievement as the country's first facility dedicated to manufacturing AC metro coaches. With a vision for regional development, it promises to address unemployment challenges in Marathwada while generating substantial employment opportunities and fostering industrial-commercial growth.       ",

      Marathwada_countup11:"Crore Investment exceeding ",
      
      Marathwada_countup12:"Acres  ",
      Marathwada_countup13:"Direct and Indirect jobs",

      Marathwada_heading2:"Promotion of Economic Development in Marathwada",
      Marathwada_para2:"The groundbreaking ceremony, graced by Union Minister Shri. Piyush Goyal and then-Chief Minister Devendra Fadnavis, took place on March 31, 2018. Remarkably, within a span of just six years, the factory was completed, culminating in its inauguration on March 12, 2024, by Prime Minister Narendra Modi.      Even amidst the challenges of the COVID-19 pandemic, the factory demonstrated resilience, achieving the milestone of completing its first railway coach shell as scheduled in 2020. Recognizing its capacity and efficiency, the central government has announced plans to manufacture 120 Vande Bharat Express trains in this facility, heralding a significant boost to Marathwada's industrial landscape.  The Marathwada Railway Coach Factory epitomizes progress and opportunity, symbolizing a transformative leap in the region's industrial journey.       ",
      Marathwada_gallery:"Snapshots",
      Marathwada_counter:"Scope of the Project",

      // Jal=============================================================

      
      Jal_saksharta_heading:"Jal Saksharta Rally",
      Jal_saksharta_para:"To permanently resolve the water issue in Latur district and to ensure that Latur receives its rightful share of water from the amount flowing into the Arabian Sea, a 'Jal Saksharta Rally' was organised across the entire district of Latur from 19th to 26th September 2023 under the initiative of MLA Sambhaji Patil Nilangekar. During this time, thousands of volunteers left their homes during the Gauri-Ganpati festival and spent eight days travelling throughout the district, meeting citizens, particularly Ganesh mandals, and raising awareness. They enlightened people about the current water situation in the district, the problems arising from it, the future seriousness of the issue, and the solutions to be implemented. Finally, on September 26, the rally concluded in Latur city in the presence of lakhs of Latur residents. ",
      

      Jal_countup1:"Days",      
      Jal_countup2:"Talukas",
      Jal_countup3:"kms Bike Ride. ",
      Jal_countup4:"Villages ",      
      Jal_countup5:"Gram Panchayats",
      Jal_countup6:"Letters of Support",

      Jal_heading2:"Fair water – this is our demand!",
      Jal_para2:"During this 'Jal Saksharta Rally', the state government was demanded to urgently take three immediate, medium-term, and long-term solutions to permanently solve the problem of water scarcity in Latur. In which, three measures were suggested - maintenance of retention ponds in the district, increasing the storage capacity of the dams that supply water to the districts by removing the silt and out of the 167 TMC of water supplied to Marathwada, Latur's share of water should be distributed to the source and not brought through the diverting water from the Godavari River to the Manjra valley via the Bhandardara route. So that everyone will get water in a fair and healthy manner without any water dispute in any district.",


    //   Taas_Andolan=============================================================

      
    Taas_saksharta_heading:"72 Tass Annatyag Andolan ",
    Taas_saksharta_para:"On 11 October 2021, MLA Sambhaji Patil Nilangekar began the hunger strike '72 Taas Annatyag Andolan' to protest the anti-farmer policies of the then Maha Vikas Aghadi government and highlight the government's neglect of farmers suffering from loss. Thousands of farmers came together and went on a hunger strike with Sambhaji Patil Nilagekar to underline the government's aristocratic attitude. During this time, 14 farmers had to be taken to the hospital, and finally, after three days, the government backed down and announced an aid of 10 thousand crore rupees for the affected farmers. ",

    Taas_countup1:"hours duration",      
    Taas_countup2:"thousand crore rupees of assistance",
    Taas_countup3:"participation of protestors",
    

    Taas_heading2:"Sacrificing food for food!  ",
    Taas_para2:"Many socio-political organizations including farmers across the state also supported the movement. Many people in the state and center supported the movement by visiting it and also through video messages. Nagtirthwadi village of Latur district supported its farmer brothers by taking the role of 'Ek Diwas Chulband'. In the protest, 25 protestors protested against the policies of the government by shaving their heads. At that time, everyone took a firm stand to sacrifice food for the food giver and get him justice. Finally, the movement was successful and the then coalition government announced aid. ",

    // Latur=============================================================

      
    Latur_saksharta_heading:"Jan Jagar Samvad",
    Latur_saksharta_para:"Even after 75 years of independence, Marathwada has not fully developed. Today, many districts in Marathwada, including Latur, are grappling with basic necessities like drinking water. Where there's a scarcity of water, the challenges extend to industries, employment, and infrastructure. Due to the lack of a conducive development environment and employment opportunities in the district, there has been significant youth and citizen displacement. To address these issues and promote the overall development of the Latur district, the Honorable Sambhaji Patil Nilangekar initiated and guided the establishment of a development platform called 'Jan Jagar Manch'.",
    Latur_countup1:"Expert Participants Engaged",      
    Latur_countup2:"Area White Papers Produced",
    Latur_countup3:"Brainstorming Development Points  ",
    

    Latur_heading2:"Discussion on the overall development of Latur",
    Latur_para2:"The forum decided to study the Israeli model and focus on 9 specific areas. A total of 450 experts and practitioners from each of these sectors were to be brought together onto a single platform for strategic discussions aimed at preparing a white paper for the district's development. On August 12, 2023, a one-day dialogue program called 'Jan Jagar Samvad' was organized at the Dayanand Auditorium in Latur. Nine separate sessions covering nine different subjects were conducted during this event. Additionally, nine distinct white papers related to each area were prepared and presented before the audience. ",

    // Atal=============================================================

      
    Atal_saksharta_heading:"Atal Mahaarogya Shibir",
    Atal_saksharta_para:"Under the leadership of MLA Sambhaji Patil Nilangekar, a state government-sponsored health camp called 'Atal Mahaarogya Shibir' was successfully organised from October 1st to 7th, 2018. During the 6-day campaign, more than 1 lakh citizens were registered and pre-screened for health evaluations across the district. On October 7th, the then Chief Minister, Mr. Devendraji Fadnavis, inaugurated the camp, where free surgeries and medical treatments were provided to those in need.",
    Atal_countup1:"Lakh Patients Examination",      
    Atal_countup2:"Surgical Diagnoses",
    Atal_countup3:"Successful Treatments  ",
    

    Atal_heading2:"Providing free health facilities for the poor!",
    Atal_para2:"This marked the first time in the history of Latur district that such a large-scale health camp was organized. The District Health Department, along with dedicated Asha volunteers, worked tirelessly day and night to ensure its success. Additionally, 23 coordination committees were appointed to assist the health department in organizing the camp effectively. Following the camp, patients enrolled in the program received free treatment through various government healthcare schemes. Successful completion of surgeries, including a hip replacement procedure performed on Mr. Vishnuji Santoba Surnar from Ahmedpur.",

    // Namo=============================================================

      
    Namo_saksharta_heading:"NAMO Maharojgar Melava",
    Namo_saksharta_para:"On 23rd February 2024, the 'NAMO Maharojgar and Career Guidance Melava' was held at Mahila Tantra Niketan Maidan, Latur, under the guidance of MLA Sambhaji Patil Nilangekar. This grand event aimed to shape the future of Marathwada's youth, recognising their immense potential and the need to provide them with proper direction. Over 240 reputed companies participated in the Maharojgar melava, offering more than 7000 employment opportunities. Additionally, numerous youths received valuable guidance and support in business-oriented matters, with special programs tailored for women and girls. ",
    Namo_countup1:"Companies",      
    Namo_countup2:"Job Openings",
    Namo_countup3:"  ",
    

    Namo_heading2:"Ensuring employment for everyone is our goal!",
    Namo_para2:"The event also witnessed the presence of HR representatives from over 180 companies, further boosting employment prospects for the youth. To cater to the specific needs of women and farmers, dedicated wings were established within the ‘Mahamela’, providing industry and business guidance tailored to their requirements. The enthusiastic participation of young women from Marathwada added to the success of this grand gathering.",


     // indraprashth=============================================================

      
     Indraprashtha11_saksharta_heading:"Indraprastha Jalbhumi Abhiyan",
     Indraprashtha11_saksharta_para:"With the aim of making Latur and Marathwada 'drought-free' and 'water-abundant', Mr. Sambhaji Patil Nilangekar initiated the Indraprastha Jalbhumi Abhiyan. Implemented from May 22 to June 5, 2018, across the entire Latur district, this campaign aimed to educate citizens about the importance of water conservation through door-to-door outreach. The mission successfully recharged 2800 borewells and wells in the district by providing free materials and labor support. As a result, a significant increase of 8 meters was recorded in the groundwater level of the district. ",
     Indraprashtha11_countup1:"Villages",      
     Indraprashtha11_countup2:"Recharged Borewells",
     Indraprashtha11_countup3:" Meter increase in  groundwater level of the district",
     
 
     Indraprashtha11_heading2:"Home Awareness, Water Conservation Progress!",
     Indraprashtha11_para2:"Achieved an 8-meter increase in the groundwater level of the district Awareness at home, progress in water conservation! This comprehensive campaign covered 945 villages, where citizens received training on water conservation and were encouraged to plant trees through doorstep distribution. Additionally, cleanliness drives were organized to raise awareness about the importance of sanitation. Citizens who actively participated and completed the water conservation training were honored with the title of 'Jala Yoddha'.",

     // akka=============================================================

      
     akka12_saksharta_heading:"Akka Foundation",
     akka12_saksharta_para:"In 2016, Latur district faced severe drought. During this challenging period, various initiatives were launched to aid the people of Latur under the guidance of Sambhaji bhaiyya and his mother, Smt. Rupatai (Akka) Patil Nilangekar. The inception of 'Akka Foundation' was driven by the vision of Sambhaji bhaiyya and his younger brother, Arvind Patil Nilangekar, in response to Akka's desire for a charitable organization to support the community during crises. ",
     
     

     akka12_heading2:"Empowering Every Facet of Society!     ",
     akka12_para2:"Akka Foundation is dedicated to fostering the well-being and prosperity of underprivileged individuals in our society. The foundation is actively engaged in initiatives spanning women empowerment, education, healthcare, environmental conservation, water management, support for farming communities, and the preservation of art and culture. Notably, projects like Project Anand for adolescent girls and Drashti Abhiyan, providing free eye care services to senior citizens, have significantly impacted many lives. Through these endeavors, the foundation has garnered increased trust from the community, with widespread participation in its activities.",

     akka_swiper1_heading:"Empowerment",
     akka_swiper1_para:"Empowering the underprivileged lies at the core of Akka Foundation's mission for sustainable development. It entails providing sustainable opportunities, motivation, and resources to those facing economic and social challenges. By nurturing skills development and providing financial support, the foundation aims to make individuals self-reliant for a sustainable future.",

     akka_swiper2_heading:"Education",
     akka_swiper2_para:"In a country with over a billion people, only one-third can read due to challenges like a lack of basic facilities, resources, and teachers, along with insufficient public funds for education—creating a significant barrier to achieving sustainable development.",

     akka_swiper3_heading:"Environment",
     akka_swiper3_para:"In the intricate web of rural welfare, the environment stands as a linchpin for Akka Foundation, playing a pivotal role in fostering sustainable development.",

     akka_swiper4_heading:"Health",
     akka_swiper4_para:"Prioritising health is a fundamental aspect of Akka Foundation's mission for sustainable development among underprivileged communities. Health, being a cornerstone of individual and societal well-being, is integral for achieving long-term progress.",

     akka_swiper5_heading:"Art & Culture",
     akka_swiper5_para:"Art and culture form the vibrant tapestry of Akka Foundation's holistic approach to rural welfare, contributing profoundly to sustainable development. Recognizing the transformative power of artistic expression, the foundation integrates art and culture into its initiatives, fostering a sense of identity, pride, and unity within communities.",

     akka_swiper6_heading:"Project Anandi",
     akka_swiper6_para:"An innovative initiative ‘Project Anandi’ was launched as a sustainable solution to the menstrual health problems of women and girls in rural areas.",

     akka_read_more:"Read More",  
     project_anandi:"Project anandi",
     school:"School",
     anandi_days:"Days",
     anandi_students:"No. of students",
     drishti_abhiyan:"Drushti Abhiyan",
     drishti_total_pateints:"Total  Patients",
     drishti_total_ooperations:"Total Operations",
     drishti_specs_distructed:"Specs Distructed",
        


        // about--page===========================
        About_heading:"Few Words From Me To You...",
        About_para1:"I have been involved in politics for the past two decades. During this time, I have had the privilege of working alongside esteemed leaders such as former Chief Minister Dr. Karmveer Shivajirao Nilangekar and former MLA Diliparavji Patil Nilangekar, as well as our former MP from Latur, Rupatai (Akka) Patil Nilangekar. My journey began as a humble worker, driven by a commitment to public service and the vision of development for every citizen of the Latur district. With the support and blessings of the people, I have contributed to the development of Maharashtra. This includes initiatives such as establishing the country's first metro coach and the fourth railway coach factory, implementing the water grid project to address drought in Latur and Osmanabad districts, improving district roads, and approving a super speciality hospital in Latur. Thanks to your cooperation, we achieved significant milestones, such as winning power in the Zilla Parishad and Municipal Corporation for the first time in the history of the Latur district. This recognition reaffirmed our commitment to public service and the ideals of the BJP.",
        About_para2:"Our district is making steady progress today, but challenges are still ahead, including water scarcity, unemployment, and healthcare. To address these issues, we have launched various service initiatives such as the Indraprastha Jalbhumi Abhiyan, Water Literacy Rally, Janjagar Samvad, Maharojgar Melawa, and Health Camps. Additionally, we are tirelessly working to secure approval for further development projects. I am deeply grateful for the unwavering support of the people of Nilanga, Latur City, and Latur District. I assure you that I will continue to work tirelessly to address your concerns and uphold your trust. With your support and blessings, I am committed to steering Latur towards a brighter future in education, employment, healthcare, and water management. Your encouragement is vital for the success of our endeavours, and I am confident that together, we can overcome any challenges. Hon. Sambhajirao Patil Nilangekar Former Guardian Minister, Latur",

        About_personality:"Biographical Sketch",
        About_personality_para:"Latur district has always been at the forefront of Maharashtra's political scene, producing many illustrious leaders who have significantly contributed to the state and the country. Among these notable figures, Sambhaji Patil Nilangekar stands as a shining beacon of this proud tradition. In the 2004 assembly elections, he achieved the remarkable distinction of becoming the youngest MLA from Marathwada. Emerging from a family with a storied political legacy, Sambhaji Patil Nilangekar has tirelessly forged his path, establishing a distinct and respected identity over the past few years. His journey is nothing short of inspiring. Starting his career as a commercial pilot, he has soared to new heights, now serving as a pivotal force in the development of Marathwada, especially in the Latur and Dharashiv districts. Today, he is celebrated as an inclusive, down-to-earth, and immensely popular leader whose dedication and vision have earned him widespread admiration and respect throughout Marathwada. His story is one of relentless pursuit, steadfast commitment, and transformative leadership.",

        About_card1:"Educated And Intellectually Curious Leadership",
        About_card1_para:"Sambhaji Patil Nilangekar is fondly known as 'Vikas-cha Pilot' by many media and citizens. However, only some know that he was a commercial pilot and had worked as a commercial pilot on the Australia-Singapore route before entering the political arena. At the same time, he completed his engineering education at Jawaharlal Nehru Engineering College, Chhatrapati Sambhaji Nagar. He has always believed that education is the foundation of individual and national prosperity, so his deep interest in the field stems from this. He is committed to ensuring that children in rural areas receive quality education. Latur district has a long tradition of valuing education, and during his political tenure, he has devoted particular attention to developing the district's education sector.",

        About_card2:"Beliefs And A Heritage In Politics",
        About_card2_para:"Sambhaji Patil Nilangekar entered active politics in 2004 under the leadership of his mother, Smt. Rupatai (Akka) Patil Nilangekar, the BJP, won for the first time in the Latur district, breaking the long-standing stronghold of the Congress party. Sambhaji Patil Nilangekar planned this election in detail. Subsequently, he stood for the Legislative Assembly for the first time and won by a vast majority, entering the Legislature. The development work he initiated and his dedicated approach was widely recognised. He worked diligently to strengthen the party's presence throughout the Latur district, playing a significant role in the party's current strong position there. Recognising his efforts, the party entrusted him with many responsibilities, the most significant being the position of regional president of Yuva Morcha.",

        About_card3:"Leading The Yuva Morcha",
        About_card3_para:"Hon. Sambhaji Patil Nilangekar's contributions were invaluable in strengthening the party organisation in the Latur district. Seeing his effective work style, the party appointed him as the State President of Yuva Morcha in 2010. Over the next three years, he travelled across Maharashtra, forming youth organisations in every district. He inspired young people to join the Bharatiya Janata Party and work for the development of Maharashtra and the country. This effort led to the expansion of Yuva Morcha in many villages. The number of youth workers in the party increased significantly. During the period from 2010 to 2013, the Yuva Morcha grew tremendously in the state, and these youth activists played a significant role in the 2014 elections.",

        
        About_card4:"Cabinet Minister: Chariot of Development from District Level to State Level",
        About_card4_para:"In the 2014 Lok Sabha and Vidhan Sabha elections, under the leadership of the popular Prime Minister Mr Narendra Modi, Sambhaji Patil Nilangekar once again won from Nilanga Assembly. Under the leadership of the then Chief Minister of the state, Hon'ble Shri Devendra Fadnavis, a wave of development arose. In the cabinet expansion 2017, Mr Sambhaji Patil was included as a cabinet minister. Interestingly, he was given responsibility for four departments: Labour, Food, Civil Supplies and Consumer Protection, Earthquake Rehabilitation and Ex-Servicemen Welfare, along with Skill Development, Prime Minister Modi's favourite portfolio. He initiated many public welfare schemes during this time.",

        About_card5:"A Leader Who Loves Agriculture and Farmers",
        About_card5_para:"Agriculture and farmers' welfare stay close to Hon. Sambhaji Patil Nilangekar's heart. He firmly believes that a healthy and strong society can only stand if our farmers and food providers are happy. He is determined to address the issue of farmer suicides, which he considers the greatest tragedy of our society. To support farmers, he is continuously implementing various activities to accelerate their income. Along with increasing revenue, he consistently advocates for issues such as loan waivers, compensation, crop insurance, water availability for agriculture, and financial assistance. His '72 Hours Food Sacrifice' movement against the Maha Vikas Aghadi government garnered statewide attention, demanding compensation for farmers.",

        About_card6:"Jal Nayak: Ardent Advocate for Water-Secure Latur",
        About_card6_para:"Marathwada has always been known as a drought-prone region, with Latur and Dharashiv districts being the most affected. During the severe drought in 2016, water had to be delivered to Latur by rail. Recognising this as a serious issue, he aimed to make Latur water-secure and initiated various water management projects. He emphasised the effective implementation of government schemes like social forestry and Jalyukta Shivar in the district. As guardian minister, he conceptualised and implemented the 'Indraprastha Jalbhumi Abhiyan.' This initiative raised the groundwater level of the entire district by as much as 8 metres, and Latur was declared a tanker-free district.",

        About_card7:"A Dutiful Brother to Women and Girls",
        About_card7_para:"Women are the real backbone of society, and their development cannot be neglected. Mr Sambhaji Patil Nilangekar is passionate about the development of women and girls. Born from his vision and a simple yet powerful idea of 'how we can educate and empower young girls,' 'Project Anandi' was initiated by the Akka Foundation to support and uplift young girls. He has implemented activities such as vocational training classes, self-help groups, and small businesses to make women economically self-reliant. He also encourages women's participation in social and political spheres. Recognising that today's young women are the architects of tomorrow's nation, he prioritises their physical and mental well-being. In alignment with this vision, 'Project Anandi' focuses on providing guidance on menstruation and offering essential health support.",

        About_card8:"Devoted to Youth Welfare and Employment",
        About_card8_para:"The youth are the cornerstone of our nation, and their holistic development holds immense significance. Sadly, prolonged drought and limited industrial growth have triggered significant migration, particularly among the youth, from the Latur district over recent decades. Approximately 5,000 youths leave the district annually, significantly affecting its progress. Addressing this challenge and fostering local industrialisation remains a steadfast commitment for Mr Sambhaji Patil Sir. His efforts have borne fruit in initiatives like the Marathwada Railway Coach Factory, poised to generate 45,000 jobs locally. Furthermore, he champions various initiatives such as employment fairs, career guidance camps, skill development centres, and competitive examination guidance centres tailored for the youth. During his tenure as the Minister of Skill Development, he implemented several pivotal decisions benefiting the youth. ",

        About_card9:"A Social Worker with a Touch of Spirituality",
        About_card9_para:"Hon. Sambhaji Patil Nilangekar embodies a deep sense of spirituality alongside his dedication to social and political endeavours. His spiritual inclinations guide his actions and decisions. He holds profound reverence for revered deities such as Shri Nilakanteshwar Mahadev of Nilanga, Sri Tuljabhavani, Dattatraya Maharaj of Gangapur, Shri Swami Samarth, and Sri Tirupati Balaji. Each day begins with prayers at the temple, followed by moments of reflection. Paying homage to sacred sites and seeking the blessings of holy figures is a cherished practice. Annually, he undertakes a pilgrimage to Gangapur with his mother to perform rituals of devotion. Additionally, the onset of the new year is marked with reverence to Sri Tirupati Balaji. His spiritual journey includes visits to religious sites, aiding in temple restoration, participating in religious events like Harinam Saptah, and engaging with devotees and Varkaris. His humility and approachability resonate deeply with the community.",
// health page
Health_title:"24/7 support, anytime, anywhere",
Health_desc:"Need assistance? We're here for you. We are available through helpline numbers to stay in touch with you and find solutions to your problems. It is now our responsibility to solve each of our problems. You submit your problem, and we are committed to solving it!",
Health_sub:"Help is always a call away",
Read_more_btn:"Learn More",
number:"8379832555",

        


   

    

    


    

   

    









































  },
];
export default english;